import { v4 } from "uuid";

const validationJson = {
  required: false,
};
const optionJson = {
  name: "",
  other: false,
};
const contentJson = {
  type: "title",
  name: "",
  description: "",
  question_type: "input",
  rows: [{ id: v4(), ...optionJson }],
  options: [{ id: v4(), ...optionJson }],
  file_types: ["image"],
  file_number: 1,
  file_size: 1, // In MB
  linear_scale_from: 1,
  linear_scale_to: 5,
  linear_scale_from_label: "",
  linear_scale_to_label: "",
  validation: validationJson,
};
const sectionJson = {
  contents: [{ id: v4(), ...contentJson }],
};
const formJson = {
  name: "",
  is_global: false,
  workspace_ids: [],
  sections: [{ id: v4(), ...sectionJson }],
};

const settingsJson = {
  to_emails: [],
  confirmation_message: "",
  dataset_id: "",
  credential_id: null,
  credential_name: "",
  sync_enabled: false,
};

const allJosn = {
  validationJson,
  optionJson,
  contentJson,
  sectionJson,
  formJson,
  settingsJson,
};

export default allJosn;
