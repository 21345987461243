import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import { apiRequest } from "src/async/apiUtils";
import EditOnHoverInput from "src/components/Shared/Forms/Inputs/EditOnHoverInput";
import StylesBuilder from "src/components/Shared/Styles/StylesBuilder";
import { noFavicon } from "src/config/host";
import EditContainer from "src/components/Shared/Containers/EditContainer";

const SiteThemeAndStyles = ({ ...props }) => {
  const [siteName, setSiteName] = useState(props?.site.site_name || "");
  const [siteLoading, setSiteLoading] = useState(false);
  const [updatedSite, setUpdatedSite] = useState({});

  useEffect(() => {
    if (props.site?._id) {
      setUpdatedSite(getSiteData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.site._id]);

  const getSiteData = (type = "all") => {
    if (type === "all") {
      return {
        _id: props.site._id,
        style_key: "workspace_id",
        name: props.site.name,
        image_logo: props.site.image_logo || "",
        square_logo: props.site.square_logo || "",
        image_favicon: props.site.image_favicon || noFavicon,
        top_bar_color: props.site?.top_bar_color || "#FFFFFF",
        top_bar_text_color: props.site?.top_bar_text_color || "#FFFFFF",
        side_bar_color: props.site?.side_bar_color || "#FFFFFF",
        side_bar_text_color: props.site?.side_bar_text_color || "#FFFFFF",
        side_bar_shade_color: props.site?.side_bar_shade_color || "#FFFFFF",
        left_nav_active_text_color: props.site?.left_nav_active_text_color || "#FFFFFF",
        highlight_color: props.site?.highlight_color || "#FFFFFF",
        background_color: props.site?.background_color || "#FFFFFF",
        sso_button_color: props.site.sso_button_color,
        sso_button_text_color: props.site.sso_button_text_color,
        max_width: props.site.max_width,
      };
    } else if (type === "layout") {
      return {
        _id: props.site._id,
        style_key: "workspace_id",
        name: props.site.name,
        image_logo: props.site.image_logo || "",
        image_favicon: props.site.image_favicon || noFavicon,
        top_bar_color: props.site?.top_bar_color || "#FFFFFF",
        top_bar_text_color: props.site?.top_bar_text_color || "#FFFFFF",
        side_bar_color: props.site?.side_bar_color || "#FFFFFF",
        side_bar_text_color: props.site?.side_bar_text_color || "#FFFFFF",
        side_bar_shade_color: props.site?.side_bar_shade_color || "#FFFFFF",
        left_nav_active_text_color: props.site?.left_nav_active_text_color || "#FFFFFF",
        highlight_color: props.site?.highlight_color || "#FFFFFF",
        background_color: props.site?.background_color || "#FFFFFF",
        sso_button_color: props.site.sso_button_color,
        sso_button_text_color: props.site.sso_button_text_color,
      };
    } else if (type === "square_logo") {
      return {
        _id: props.site._id,
        style_key: "workspace_id",
        square_logo: props.site.square_logo || "",
      };
    }
  };

  const handleStylesUpdate = async (body) => {
    let updatedValues = body ? body : updatedSite;
    setSiteLoading(true);

    if (updatedValues.max_width && !/^(\d{1,2}(\.\d+)?%|100%|\d+(?:\.\d+)?px)$/.test(updatedValues.max_width)) {
      toast.error("Please enter a valid max width. For example, 100% or 100px.");
      return;
    }

    if (updatedValues.image_favicon !== props?.site.image_favicon) {
      await apiRequest("DELETE", `/site-settings/images/image_favicon`);
    }
    if (updatedValues.image_logo !== props?.site.image_logo) {
      await apiRequest("DELETE", `/site-settings/images/image_logo`);
    }

    try {
      // await apiRequest("PUT", `/site-settings/styles`, { updatedValues: { ...updatedValues, site_name: siteName } });
      const message = await props.manageSiteSettings({ ...updatedValues, site_name: siteName });
      setTimeout(() => {
        toast.success(message);
      }, 300);
    } catch (error) {
      setTimeout(() => {
        toast.error(error.message);
      }, 300);
    } finally {
      setSiteLoading(false);
    }
  };

  useEffect(() => {
    setSiteName(props?.site.site_name);
  }, [props?.site.site_name]);

  const handleStylesDataChange = (keyValue = {}) => {
    setUpdatedSite((updatedSite) => ({ ...updatedSite, ...keyValue }));
  };

  const handleSquareLogoStylesDataChange = (keyValue = {}) => {
    setUpdatedSite((updatedSite) => ({ ...updatedSite, ...keyValue }));
  };

  const onClear = (type = "all") => {
    setUpdatedSite({
      ...updatedSite,
      ...getSiteData(type),
    });
  };

  return (
    <EditContainer
      title="Choose your colors"
      preview={{ text: "White-label the customer experience with customize colors and logos." }}
      onSuccess={handleStylesUpdate}
      onCancel={onClear}
      defaultOpen={true}
      fullWidth={true}
      borderBottom={false}>
      <div className="mt-8">
        <StylesBuilder
          data={{ ...props?.site, name: props?.site.site_name }}
          handleBrowserTabText={
            <EditOnHoverInput
              className="max-w-[120px] font-medium text-gray-700"
              value={siteName}
              onChange={(e) => {
                setSiteName(e.target.value);
              }}
            />
          }
          title="Styles & Text"
          secondaryTitle="Select the perfect set of colors and styles along with a logo and favicon."
          updatedSite={updatedSite}
          setUpdatedSite={setUpdatedSite}
          getSiteData={getSiteData}
          handleStylesDataChange={handleStylesDataChange}
          handleSquareLogoStylesDataChange={handleSquareLogoStylesDataChange}
          propsButtonOption={{ buttonLoader: siteLoading, siteOnly: true }}
        />
      </div>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(SiteThemeAndStyles);
